<script>
import api from '@/services/api';
import PageTitle from '@/components/PageTitle';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear';
import {
    BButton,
    BCol,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BModal,
    BPagination,
    BRow,
    BTable
} from 'bootstrap-vue';
import TableActionBtns from '@/components/TableActionBtns';
import CRUDPageMixin from '@/mixins/CRUDPageMixin';
import { checkLang, generateIconColorToBoolean, generateIconToBoolean } from '@/util/helper';
import vSelect from 'vue-select';
import { isArray, isObject } from '@/util/inspect.util';
import pasteImageFileAgent from '@/mixins/pasteImageFileAgent';
import { MERCHANT_ID } from '@/constants/names';

export default {
    name: 'AppCoinAward',
    components: {
        TableActionBtns,
        ProgressLinear,
        PageTitle,
        vSelect,
        BButton,
        BTable,
        BModal,
        BCol,
        BFormCheckbox,
        BFormGroup,
        BRow,
        BFormInput,
        BPagination
    },
    mixins: [CRUDPageMixin, pasteImageFileAgent],
    data() {
        return {
            items: [],
            merchantOptions: null,
            dataForModal: {
                name: {
                    uz: '',
                    ru: '',
                    en: ''
                },
                description: {
                    uz: '',
                    ru: '',
                    en: ''
                },
                position: 0,
                type: null,
                merchant: [],
                is_published: false,
                images: [],
                images_id: []
            },
            pagination: {
                current: 1,
                totalItem: '',
                perPage: 10
            },
            uploadHeaders: { 'X-Test-Header': 'vue-file-agent' },
            uploadImage: null,
            localLang: localStorage.getItem('lang'),
            requestPending: false,
            fileRecordsForUpload: [],
            imageUploaded: false,
            loadingImage: false
        };
    },

    watch: {
        '$route.query': {
            handler(query) {
                this.pagination.current = query.page || 1;
                this.getAwards();
            },
            deep: true,
            immediate: true
        },

        'pagination.current': {
            handler(page) {
                let newPage = page;
                if (this.pagination.current > this.pagination.totalItem) {
                    newPage = 1;
                }
                this.replaceRouter({
                    ...this.query,
                    page: newPage
                });
            }
        },

        uploadImage(image) {
            this.dataForModal.images = [];
            if (isArray(image)) {
                this.dataForModal.images = image;
            } else if (isObject(image)) {
                this.dataForModal.images.push(image);
            }
        }
    },

    computed: {
        fields() {
            return [
                {
                    key: 'id',
                    label: '#'
                },
                {
                    key: 'name',
                    label: this.$t('titles.title')
                },
                {
                    key: 'description',
                    label: this.$t('titles.description')
                },
                {
                    key: 'type',
                    label: this.$t('titles.type')
                },
                {
                    key: 'position',
                    label: this.$t('titles.position')
                },
                {
                    key: 'merchant',
                    label: this.$t('titles.merchant')
                },
                {
                    key: 'is_published',
                    label: this.$t('titles.is_published')
                },
                {
                    key: 'actions',
                    label: ''
                }
            ];
        },
        query() {
            return Object.assign({}, this.$route.query);
        },

        hasItems() {
            return this.items && this.items.length > 0;
        },

        showPagination() {
            return this.hasItems && !this.requestPending;
        }
        // uploadImage: {
        //     get() {
        //         const images = this.dataForModal.images;
        //         if (isArray(images) && images.length) {
        //             return images[0];
        //         }
        //         return null;
        //     },
        //     set(image) {
        //         this.dataForModal.images = [];
        //         this.dataForModal.images.push(image);
        //     }
        // }
    },

    async mounted() {
        this.requestPending = true;
        await this.getMerchants();
        this.requestPending = false;
    },

    methods: {
        checkLang,

        async getMerchants() {
            const body = {
                method: 'coin.merchant_get_all',
                params: {
                    page: 1,
                    limit: 30
                }
            };
            await api.coin
                .fetchCoinMerchants(body)
                .then((response) => {
                    this.merchantOptions = response.data.result;
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async getAwards() {
            const body = {
                page: this.pagination.current,
                limit: this.pagination.perPage,
                merchant_id: MERCHANT_ID
            };
            await api.coin
                .fetchCoinTypeAwards(body)
                .then((response) => {
                    this.items = response.data.result;
                    this.pagination = response.data.pagination;

                    if (this.pagination.current > this.pagination.totalItem) {
                        this.pagination.current = 1;
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async createAward(body) {
            delete body.merchant;
            return await api.coin.createAwardType(body);
        },

        async updateAward(body, config) {
            delete body.merchant;
            body.id = this.updatingItem.id;
            return await api.coin.updateCoinAwardType(body, config);
        },

        async deleteAward(id) {
            const params = { id };
            return await api.coin.deleteCoinAwardType(params);
        },

        async generateParamsForSubmit({ merchant }) {
            try {
                let body = Object.assign({}, this.dataForModal);

                body.merchant_id = merchant;
                body.upload_id = this.dataForModal.images[0].id;

                delete body.images;
                delete body.upload;
                delete body.images_id;

                const config = {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                };

                if (
                    this.updatingItem &&
                    (this.updatingItem.merchant === merchant ||
                        this.updatingItem.merchant?.id === merchant)
                ) {
                    return await this.updateAward(body, config);
                } else {
                    return await this.createAward(body, config);
                }
            } catch (error) {
                console.error('error', error);
                this.$toast.error(this.$t('error.smth'));
            }
        },

        async send() {
            let { merchant } = this.dataForModal;
            const pList = [];

            if (isObject(merchant) && !isArray(merchant)) {
                merchant = [merchant];
            }

            for (let i = 0; i < merchant.length; i++) {
                pList.push(
                    this.generateParamsForSubmit({
                        merchant: merchant[i].id
                    })
                );
            }

            await Promise.allSettled(pList).then(async () => {
                if (!this.updatingItem) {
                    this.$toast.success(this.$t('success.added'));
                } else {
                    this.$toast.success(this.$t('success.updated.title'));
                }

                this.isOpenModal = false;
                await this.getAwards();
            });
        },

        async submitModal() {
            this.$refs.createUpdateModal.validate().then(async (success) => {
                if (success) {
                    await this.send();
                }
            });
        },

        changeAppID(appID) {
            this.activeAppID = appID;
            this.getAwards();
        },

        changePerPage(perPage) {
            this.newsParams = {
                perPage,
                page: 1
            };
            this.getAwards();
        },

        replaceRouter(query) {
            this.$router.replace({ query }).catch(() => {});
        },

        generateIconToBoolean,
        generateIconColorToBoolean,

        fileDeleted: function (fileRecord) {
            const i = this.fileRecordsForUpload.indexOf(fileRecord);
            if (i !== -1) {
                this.fileRecordsForUpload.splice(i, 1);
            } else {
                this.deleteUploadedFile(fileRecord);
            }
        },

        deleteUploadedFile: function (fileRecord) {
            // Using the default uploader. You may use another uploader instead.
            this.$refs.vueFileAgent.deleteUpload(this.uploadUrl, this.uploadHeaders, fileRecord);
        },

        filesSelected: function (fileRecordsNewlySelected) {
            const validFileRecords = fileRecordsNewlySelected.filter(
                (fileRecord) => !fileRecord.error
            );
            this.fileRecordsForUpload = this.fileRecordsForUpload.concat(validFileRecords);
            this.imageUploaded = false;
        },

        onBeforeDelete: function (fileRecord) {
            const i = this.fileRecordsForUpload.indexOf(fileRecord);
            if (i !== -1) {
                // queued file, not yet uploaded. Just remove from the arrays
                this.fileRecordsForUpload.splice(i, 1);
                const k = this.fileRecords.indexOf(fileRecord);
                if (k !== -1) this.fileRecords.splice(k, 1);
            } else {
                this.$swal({
                    title: this.$t('success.delete.text_1'),
                    text: this.$t('success.delete.text_2'),
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: this.$t('success.delete.button_1'),
                    cancelButtonText: this.$t('success.delete.button_2'),
                    customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-danger ml-1'
                    },
                    buttonsStyling: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$refs.vueFileAgent.deleteFileRecord(fileRecord); // will trigger 'delete' event
                    }
                });
            }
        },

        openAwardModal(item) {
            const image = item.upload;
            if (image) {
                this.uploadImage = {
                    id: image.id,
                    name: image.name,
                    size: image.size,
                    type: 'image/jpg',
                    url: image.path,
                    src: image.path
                };
            }

            this.openModalToUpdate(item);
        },

        beforeClose() {
            this.uploadImage = null;
            this.onCloseModal();
        },

        async getImageId() {
            this.loadingImage = true;
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            };
            await this.dataForModal.images.forEach((image, index) => {
                if (image && !image.id) {
                    const formData = new FormData();
                    formData.append('file', image.file);

                    api.imageUploader
                        .imageUpload('awards', formData, config)
                        .then(({ data }) => {
                            this.dataForModal.images[index] = {
                                id: data.result.id,
                                name: 'image.jpg',
                                size: 0,
                                type: 'image/jpg',
                                url: data.result.path,
                                src: data.result.path
                            };
                            this.imageUploaded = true;
                        })
                        .catch((e) => {
                            this.$toast.error(
                                e.response?.data?.message || e.message || this.$t('error.smth')
                            );
                        })
                        .finally(() => {
                            this.loadingImage = false;
                        });
                }
            });
        }
    }
};
</script>

<template>
    <div>
        <!--    v-if="hasAccess('coin_award','view')"-->
        <page-title>
            <template #title> {{ $t('navigation.coin_award_type') }}</template>
            <template v-if="hasAccess('coin_award', 'create')" #button>
                <b-button v-b-modal.createUpdateModal class="ml-auto" variant="success">
                    {{ $t('button.add') }}
                    <feather-icon icon="PlusIcon" />
                </b-button>
            </template>
        </page-title>

        <!--  TABLE  -->
        <div class="card">
            <progress-linear v-if="requestPending" />
            <b-table
                v-else
                :fields="fields"
                :items="items"
                :responsive="true"
                borderless
                class="news__table"
                outlined
                striped
            >
                <template #cell(id)="{ item }">
                    {{ item.id }}
                </template>
                <template #cell(name)="{ item }">
                    <div style="min-width: 200px">
                        {{ checkLang(item.name) }}
                    </div>
                </template>
                <template #cell(description)="{ item }">
                    <div style="min-width: 200px">
                        {{ checkLang(item.description) }}
                    </div>
                </template>
                <template #cell(type)="{ item }">
                    <div style="min-width: 200px">
                        {{ item.type }}
                    </div>
                </template>
                <template #cell(position)="{ item }">
                    <div style="min-width: 200px">
                        {{ item.position }}
                    </div>
                </template>
                <template #cell(merchant)="{ item }">
                    <div v-if="item.merchant" style="min-width: 200px">
                        {{ item.merchant.name }}
                    </div>
                </template>
                <template #cell(is_published)="{ item }">
                    <div style="min-width: 200px">
                        <feather-icon
                            :class="[
                                'stroke-current',
                                generateIconColorToBoolean(item.is_published)
                            ]"
                            :icon="generateIconToBoolean(item.is_published)"
                        />
                    </div>
                </template>
                <template #cell(actions)="{ item, index }">
                    <table-action-btns
                        :delete-handler="deleteAward"
                        :index="index"
                        :item="item"
                        :show-delete-button="true"
                        @openModal="openAwardModal"
                    />
                </template>
            </b-table>
        </div>

        <!--  CREATE & UPDATE MODALS  -->
        <b-modal
            id="createUpdateModal"
            v-model="isOpenModal"
            :hide-footer="false"
            :no-close-on-backdrop="true"
            :title="updatingItem ? $t('edit.coin_award') : $t('add.coin_award')"
            cancel-variant="danger"
            centered
            size="lg"
            @hidden="beforeClose"
        >
            <ValidationObserver ref="createUpdateModal">
                <b-row>
                    <!--    NAME[UZ]    -->
                    <b-col cols="4">
                        <label> {{ $t('titles.title') }} [UZ] </label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.title')} [UZ]`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.name.uz"
                                    :placeholder="`${$t('enter.title')} [UZ]`"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    NAME[RU]    -->
                    <b-col cols="4">
                        <label> {{ $t('titles.title') }} [RU] </label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.title')} [RU]`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.name.ru"
                                    :placeholder="`${$t('enter.title')} [RU]`"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--    NAME[EN]    -->
                    <b-col cols="4">
                        <label> {{ $t('titles.title') }} [EN] </label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.title')} [EN]`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.name.en"
                                    :placeholder="`${$t('enter.title')} [EN]`"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--    description[UZ]    -->
                    <b-col cols="4">
                        <label> {{ $t('titles.description') }} [UZ] </label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.description')} [UZ]`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.description.uz"
                                    :placeholder="`${$t('enter.description')} [UZ]`"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    description[RU]    -->
                    <b-col cols="4">
                        <label> {{ $t('titles.description') }} [RU] </label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.description')} [RU]`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.description.ru"
                                    :placeholder="`${$t('enter.description')} [RU]`"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--    NAME[EN]    -->
                    <b-col cols="4">
                        <label> {{ $t('titles.description') }} [EN] </label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.description')} [EN]`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.description.en"
                                    :placeholder="`${$t('enter.description')} [EN]`"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--    TYPE    -->
                    <b-col cols="6">
                        <label> {{ $t('titles.type') }} </label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.type')}`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.type"
                                    :placeholder="`${$t('enter.type')}`"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--    POSITION    -->
                    <b-col cols="6">
                        <label> {{ $t('titles.position') }} </label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.position')}`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.position"
                                    :placeholder="`${$t('enter.position')}`"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--     MERCHANT ID     -->
                    <b-col cols="8">
                        <label>Merchant</label>
                        <v-select
                            v-model="dataForModal.merchant"
                            :dense="true"
                            :multiple="true"
                            :options="merchantOptions"
                            label="name"
                        />
                    </b-col>

                    <b-col cols="4">
                        <label for="is_published">{{ $t('titles.is_published') }}</label>
                        <b-form-checkbox id="is_published" v-model="dataForModal.is_published" />
                    </b-col>

                    <div class="w-100 mb-2">
                        <label class="pl-1">{{ $t('titles.image') }}</label>

                        <b-col cols="12">
                            <ValidationProvider
                                v-slot="{ errors }"
                                :name="$t('titles.image')"
                                rules="required"
                            >
                                <VueFileAgent
                                    ref="vueFileAgent"
                                    v-model="uploadImage"
                                    :accept="'image/*,.zip'"
                                    :deletable="true"
                                    :errorText="{
                                        type: $t('vue_file_agent.file_type_error'),
                                        size: $t('vue_file_agent.size_type_error')
                                    }"
                                    :helpText="$t('vue_file_agent.choose_image')"
                                    :maxFiles="14"
                                    :maxSize="'10MB'"
                                    :meta="true"
                                    :multiple="false"
                                    :theme="'grid'"
                                    @beforedelete="onBeforeDelete($event)"
                                    @delete="fileDeleted($event)"
                                    @select="filesSelected($event)"
                                >
                                </VueFileAgent>

                                <b-button
                                    :disabled="!fileRecordsForUpload.length || imageUploaded"
                                    :variant="imageUploaded ? 'success' : 'primary'"
                                    class="float-right mt-1"
                                    @click="getImageId()"
                                >
                                    <span v-if="!imageUploaded">
                                        {{ $t('vue_file_agent.upload_image') }}
                                    </span>
                                    <span v-else>
                                        {{ $t('vue_file_agent.image_uploaded') }}
                                    </span>
                                </b-button>
                                <span class="validation__red">
                                    {{ errors[0] }}
                                </span>
                            </ValidationProvider>
                        </b-col>
                    </div>
                </b-row>
            </ValidationObserver>

            <template #modal-footer>
                <b-form-checkbox v-if="!updatingItem" v-model="createOneMore" class="mr-auto">
                    {{ $t('titles.add_more') }}
                </b-form-checkbox>
                <b-button variant="success" @click="submitModal">
                    {{ modalSubmitBtnText }}
                </b-button>
            </template>
        </b-modal>

        <!--  PAGINATION  -->
        <b-row>
            <b-col class="offset-3" cols="9">
                <b-pagination
                    v-if="showPagination"
                    v-model="pagination.current"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalItem"
                    class="float-right mr-4 mb-0"
                    first-number
                    last-number
                />
            </b-col>
        </b-row>
    </div>
</template>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

::v-deep .vs__dropdown-menu {
    min-height: auto;
    height: 100px;
}

.card-body {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0;
}

.bordered__image-list {
    border: 2px dashed #999999;

    &_item {
        position: relative;
        width: 100px;
        height: 100px;

        &-close {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -5px;
            right: -5px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: #999999;
            color: #fff;
            cursor: pointer;
            font-size: 12px;
        }

        & img {
            width: 100px;
            height: 100%;
            object-fit: cover;
        }
    }
}
</style>
